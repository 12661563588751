<template>
    <div class="container-full">
        <div class="nav">
            <a href="/">Home</a>
            <a target="_blank" href="https://discord.gg/9uyg2jrnnR">Discord</a>
            <a target="_blank" href="https://x.com/LuckyCoinProj">Twitter</a>
        </div>
        <div class="container">
            <div id="header" class="row">
                <div class="col">
                    <a href="/">
                        <img src="images/luckycoin-lucky-logo.png" alt="Luckycoin Logo" width="100" height="100">
                    </a>
                    <h1>Luckycoinpunks</h1>
                    <p>Luckycoinpunks are the first byte-perfect uploads of the original Ethereum CryptoPunks onto the Luckycoin Blockchain using Ordinals.</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <StatsView />
                </div>
            </div>
            <div v-if="RescanBanner" class="row">
                <div class="col">
                    <RescanBannerView />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StatsView from "./StatsView.vue";
import RescanBannerView from "./RescanBannerView.vue";

export default {
  name: 'ListView',
  components: {
    StatsView,
    RescanBannerView,
  },
  data() {
    return {
        RescanBanner: false
    }
  },
}

</script>

<style scoped>
    .container-full {
        background-color:  rgba(0, 0, 0, 0.3);
        margin-bottom: 3rem;
    }
    .container {
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    img{
        margin-bottom: 0.5rem;
    }
    h1{
        font-size: 2.5rem;
        color: #ffd859;
        font-weight: 700;
    }
    .nav{
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        justify-content: center;
        background-color: #000;
    }
    .nav a{
        color: #ffd859;
        font-weight: 700;
        margin: 1rem;
    }
    @media screen and (max-width: 600px) {
        .nav{
            flex-direction: column;
            text-align: center;
        }
    }
</style>