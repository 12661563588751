<template>
    <div class="alert alert-danger" role="alert">
        We would like to inform you that the Luckycoin chain recently underwent a reorganization process, and as a result, we are currently re-indexing our database. During this time, updates to inscriptions on our platform have been temporarily paused.
    </div>  
</template>

<style scoped>
.alert {
    text-align: center;
}
</style>