<template>
    <div class="stats-view container">
      <div class="row">
        <div class="progress mx-auto" style="width: 100%;">
          <div
            class="progress-bar"
            role="progressbar"
            :style="progressBarStyle"
            :aria-valuenow="stats.inscribed_items"
            aria-valuemin="0"
            aria-valuemax="stats.total_items"
          >
            {{ stats.inscribed_items }}/{{ stats.total_items }}
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        stats: {
          total_items: 0,
          inscribed_items: 0,
          last_inscription: "",
        },
      };
    },
    computed: {
      progressBarStyle() {
        const percentage = (this.stats.inscribed_items / this.stats.total_items) * 100;
        return {
          width: `${percentage}%`,
          background: `linear-gradient(45deg, #ffd859, #c8b059)`,
        };
      },
    },
    methods: {
      async fetchStats() {
        try {
          const response = await axios.get("https://api.luckycoinpunks.com/stats");
          this.stats = response.data;
        } catch (err) {
          console.error(err);
        }
      },
    },
    created() {
      this.fetchStats();
      setInterval(() => {
        this.fetchStats();
      }, 60 * 1000); // Update every minute
    },
  };
  </script>
  
  <style scoped>
  .stats-view {
    width: 100%;
    max-width: 300px;
    padding-top: 0!important;
  }
  .progress {
    height: 30px;
  }
  .progress-bar {
    color: #212020;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  </style>