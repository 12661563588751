<template>
    <div class="modal fade" id="detailPopup" tabindex="-1" aria-labelledby="detailPopupLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header d-flex justify-content-center">
            <div class="d-flex flex-column align-items-center">
                <img v-if="this.itemID" :src="'images/items/punk' + itemID + '.png'" :alt="'punk' + itemID" class="img-fluid">
                <h5 class="modal-title mt-1" id="detailPopupLabel">Punk Detail: #{{ itemID }}</h5>
            </div>
            </div>
          <div class="modal-body">
            <div class="row">
                <div class="col">
                    <button class="btn btn-outline-secondary" type="button" @click="openPFPImage">PFP Image</button>
                </div>
                <div class="col">
                    <button class="btn btn-outline-secondary" type="button" @click="openItemImage">Inscribe Image</button>
                </div>
            </div>
            <div v-if="inscriptions.length > 0">
              <div v-for="inscription in inscriptions" :key="inscription.inscription_id">
                <hr>
                <strong>Inscription Number:</strong>
                <p>{{ inscription.inscription_number }}</p>
                <strong>Inscription ID:</strong>
                <p>{{ inscription.inscription_id }}</p>
                <strong>Timestamp:</strong>
                <p>{{ inscription.inscription_timestamp }}</p>
                <strong>SHA256:</strong>
                <p>{{ inscription.inscription_sha256 }}</p>
                <strong>Owner:</strong>
                <p>{{ inscription.inscription_owner }}</p>
              </div>
            </div>
            <div v-else>
              <hr>
              <p>No inscriptions found for Punk #{{ itemID }}.</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-sm" @click="$emit('close-modal')">Close</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'DetailPopupView',
    props: {
      itemID: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        inscriptions: []
      };
    },
    watch: {
      itemID() {
        if (this.itemID) {
            this.fetchInscriptions();
        }
      }
    },
    methods: {
      async fetchInscriptions() {
        try {
          this.inscriptions = [];
          const { data } = await axios.get(`https://api.luckycoinpunks.com/inscriptions/${this.itemID}`);
          this.inscriptions = data;
        } catch (error) {
          console.error(error);
        }
      },
      openPFPImage() {
        window.open(`https://cryptopunks.app/cryptopunks/cryptopunk${this.itemID}.png?size=2500&customColor=ffd859`, '_blank');
    },
    openItemImage() {
        window.open(`https://cryptopunks.app/public/images/cryptopunks/punk${this.itemID}.png`, '_blank');
    },
    },
    created() {
        if (this.itemID) {
            this.fetchInscriptions();
        }
    }
  }
  </script>
  
  <style scoped>
  .modal-dialog {
    max-width: 600px;
  }
  .modal-header {
    background-color: #ffd859;
    color: #212020;
    text-align: center;
  }
  .modal-body {
    color: #212020;
    font-size: 0.7rem;
  }
  .modal-body button{
    width: 100%;
  }
  .modal-modal-title{
    padding: 0;
    margin: 0;
  }
  .img-fluid {
    width: 60px;
    height: auto;
    image-rendering: pixelated;
  }
  .btn {
    background-color: #ffd859;
    color: #212020;
    box-shadow: none;
    border: 1px solid #c8b059;
  }
  .btn.active, .btn:active, .btn:focus, .btn:hover, .btn:active:focus{
    background-color: #212020!important;;
    box-shadow: none!important;
    border: 1px solid #c8b059!important;
    color: #fff;
  }
  </style>